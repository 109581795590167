<template>
  <div id="app" class="clan-page">
    <div class="clan-section-violette">
      <div class="content-clan">
        <h1>
          Avec le <span class="clan-highlight-purple">Clan</span>, chaque <br />
          défi devient une
          <span class="clan-highlight-green">victoire</span>.<br />
        </h1>
        <p>
          Rejoignez un clan en fonction de votre niveau <br />
          et participez à des guerres de clans palpitantes ! <br />
          <br />

          <span class="clan-highlight-purple">• Clan Public.</span><br />
          Accessible à tous, ce type de clan vous permet de découvrir le système de jeu et de vous entraîner avec
          d'autres joueurs de tous niveaux. <br /><br />

          <span class="clan-highlight-purple">• Clan Privé.</span><br />

          Réservé aux membres expérimentés, ce clan offre des défis stratégiques exclusifs et une ambiance compétitive.
          Faites vos preuves pour rejoindre l'élite !
        </p>
      </div>
      <div class="clan-img">
        <img src="/img/clan.png" alt="Embleme de clan" class="embleme-clan" />
      </div>
    </div>

    <div class="clan-section-blanc">
      <h1>Quelques chiffres <span class="clan-highlight-purple">...</span></h1>
      <div class="clan-content">
        <div class="clan-content-1">
          <h2>Nombre de clan</h2>
          <p>Nombre total de clan crée</p>
          <h1>504 <span class="clan-highlight-green">Clans</span></h1>
        </div>
        <div class="clan-content-1">
          <h2>Défi réalisé</h2>
          <p>Nombre total des défis réalisé inter-clan</p>
          <h1>4234 <span class="clan-highlight-purple">Défis</span></h1>
        </div>
        <div class="clan-content-1">
          <h2>Nombre de point</h2>
          <p>
            Tout les points récoltées depuis le début lors de la réaslisation de
            défi
          </p>
          <h1>74520 <span class="clan-highlight-green">Points</span></h1>
        </div>
      </div>
    </div>

    <div class="clan-tableau">
      <table>
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Nom</th>
            <th scope="col">Membres</th>
            <th scope="col">Points</th>
            <th scope="col">Jours</th>
            <th scope="col">Versus</th>
            <th scope="col">AVG Win</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" class="rank-1">1</th>
            <td>
              <img src="/img/clan.png" alt="Silent Knight" style="width: 50px; height: 50px; vertical-align: middle;">
              Silent Knight
            </td>
            <td>10</td>
            <td>8940</td>
            <td>45</td>
            <td>50/5</td>
            <td>90%</td>
          </tr>
          <tr>
            <th scope="row" class="rank-2">2</th>
            <td>
              <img src="/img/clan.png" alt="Silent Knight" style="width: 50px; height: 50px; vertical-align: middle;">
              Silent Knight
            </td>
            <td>10</td>
            <td>8940</td>
            <td>45</td>
            <td>50/5</td>
            <td>90%</td>
          </tr>
          <tr>
            <th scope="row" class="rank-3">3</th>
            <td>
              <img src="/img/clan.png" alt="Silent Knight" style="width: 50px; height: 50px; vertical-align: middle;">
              Silent Knight
            </td>
            <td>10</td>
            <td>8940</td>
            <td>45</td>
            <td>50/5</td>
            <td>90%</td>
          </tr>
          <tr>
            <th scope="row">4</th>
            <td>
              <img src="/img/clan.png" alt="Silent Knight" style="width: 50px; height: 50px; vertical-align: middle;">
              Silent Knight
            </td>
            <td>10</td>
            <td>8940</td>
            <td>45</td>
            <td>50/5</td>
            <td>90%</td>
          </tr>
          <tr>
            <th scope="row">5</th>
            <td>
              <img src="/img/clan.png" alt="Silent Knight" style="width: 50px; height: 50px; vertical-align: middle;">
              Silent Knight
            </td>
            <td>10</td>
            <td>8940</td>
            <td>45</td>
            <td>50/5</td>
            <td>90%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="../assets/clan.css"></style>
