<template>
  <div class="login_css">
    <div class="gauche_login">
      <img src="/img/login.png" alt="photo du login" />
    </div>
    <div class="droite_login">
      <img src="/img/logo_kameleon.png" alt="Logo du site" />
      <form @submit.prevent="logIn" class="form_login">
        <p>Username/E-mail</p>
        <input
          type="text"
          class="icon-mail"
          v-model="usernameOrEmail"
          placeholder="Entrez votre Username/E-mail..."
        />
        <p>Mot de passe</p>
        <div class="password-container">
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            placeholder="Entrez votre Mot de passe..."
          />
          <button type="button" @click="togglePasswordVisibility" class="toggle-password">
            <img :src="showPassword ? '/img/oeil.png' : '/img/oeil_ferme.png'" alt="Afficher/Masquer le mot de passe" />
          </button>
        </div>
        <br />
        <p>Vous n'avez pas de compte ? <RouterLink to="/sign-in" class="login-color-text">Création</RouterLink></p>
        
        <br />
        <button class="button_login" type="submit">Connexion</button>
      </form>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success">{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "AppLogin",
  data() {
    return {
      usernameOrEmail: '',
      password: '',
      errorMessage: '',
      successMessage: '',
      showPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async logIn() {
      try {
        const response = await axios.post('https://kameleonback.jrcan.dev/api/login/', {
          username: this.usernameOrEmail,
          password: this.password,
        });

        const accessToken = response.data.access;

        // Token
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);

        // Get User Info
        const userResponse = await axios.get('https://kameleonback.jrcan.dev/api/user/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        localStorage.setItem('userInfo', JSON.stringify({
          username: userResponse.data.username,
          email: userResponse.data.email,
          profilePicture: "https://kameleonback.jrcan.dev/" + userResponse.data.profile_picture,
          rank: userResponse.data.rank,
          createdAt: userResponse.data.created_at,
        }));

        this.successMessage = 'Connexion réussie. Redirection...';
        this.errorMessage = '';

        setTimeout(() => {
          this.$router.push('/');
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        this.errorMessage = error.response?.data?.error || "Une erreur s'est produite.";
        this.successMessage = '';
      }
    },
  },
};
</script>

<style src="../assets/login.css"></style>
