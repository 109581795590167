import { createApp } from 'vue'
import App from './App.vue'
import router from './rooter';
import '@fortawesome/fontawesome-free/css/all.css';

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'ameleon';
    next();
});

createApp(App).use(router).mount('#app');