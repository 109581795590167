<template>
  <div id="app">
    
    <AppHeader />
    <router-view />
    <AppFooter />
    <HomePage />
  </div>
</template>


<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  }
};
</script>

<style scoped>
html, body, #app {
  height: 100%; 
  margin: 0;
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1; 
  display: flex;
  flex-direction: column;
}

main {
  flex: 1; 
}

.footer {
  flex-shrink: 0; 
}

</style>