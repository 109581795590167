<template>
    <div id="app" class="faq-page">

        <div class="faq-container">
            <div class="title-faq">
                <h1>FAQ's</h1>
            </div>
            <details>
                <summary>Comment se créer un compte ?</summary>
                <div class="faq-content">
                    Cliquez sur le bouton "Se connecter" dans le menu en haut de la page, puis sur le lien "Création".
                    Remplissez le formulaire, puis cliquez sur le bouton "Création" pour finaliser l'inscription.
                </div>

            </details>
            <details>
                <summary>Comment se connecter ?</summary>
                <div class="faq-content">
                    Cliquez sur le bouton "Se connecter" dans le menu en haut de la page.
                    Saisissez ensuite votre identifiant et votre mot de passe, puis validez en cliquant sur le bouton
                    "Connexion". </div>
            </details>
            <details>
                <summary>Comment nous contacter ?</summary>
                <div class="faq-content">
                    En bas de la page, cliquez sur le bouton "Formulaire" dans la rubrique Contact.
                    Une fois redirigé(e), remplissez le formulaire en décrivant le problème rencontré ou en posant votre
                    question, puis cliquez sur le bouton "Envoyer".
                </div>
            </details>
        </div>

    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style src="../assets/faq.css"></style>