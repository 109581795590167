<template>
  <div class="clan-page-login">
    <h1 class="clan-title-login">CLAN</h1>
    <div class="clan-container-login">
      <div class="clan-card-login">
        <img src="/img/image-rejoindre.svg" alt="Rejoindre un clan">
        <h3>Rejoindre un Clan</h3>
        <p>Tu te crois prêt à relever les défis les plus complexes ? Alors, il est temps de franchir un nouveau cap : rejoins un clan et découvre la puissance d'une équipe !</p>
        <a href="#" class="clan-button-login">Rejoindre</a>
      </div>
      <div class="clan-card-login">
        <img src="/img/image-creer.svg" alt="Créer un clan">
        <h3>Créer un Clan</h3>
        <p>Tu as l'âme d'un leader ? Une vision unique, alors il est temps de passer à l'action : crée ton propre clan et bâtis une équipe à ton image.</p>
        <a href="#" class="clan-button-login">Créer</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="../assets/clan_login.css"></style>