<template>
    <div class="footer"> 
      <div class="footer-content-1">
        <h3>à propos de nous</h3>
        <p>Kameleon est un site d'énigmes en ligne conçu pour servir de campagne de recrutement innovante pour l'entreprise R&MI.</p>
        <img src="/img/logo_kameleon.png" alt="Logo du site">
      </div>
      <div class="footer-content-2">
        <h3>Contact</h3>
        <p>Adresse : Rue de calais</p>
        <p>Téléphone : 06 62 62 62 62</p>
        <p>Email : Kameleon@hotmail.com</p>
        <button><RouterLink to="/contact">Formulaire</RouterLink></button>
      </div>
      <div class="footer-content-3">
        <h3>Navigation</h3>
        <nav class="footer-nav">
          <RouterLink to="/">Accueil</RouterLink>
          <br>
          <RouterLink to="/enigme">Énigmes</RouterLink>
          <br>
          <RouterLink to="/clan">Clan</RouterLink>
          <br>
          <RouterLink to="/versus">Versus</RouterLink>
          <br> 
          <RouterLink to="/faq">FAQ</RouterLink>
        </nav>
      </div>
    </div>
    <div class="mention">
        <p><br>© 2024 Kameleon. All Rights Reserved. <a href="/mentions-legales">Mentions légales</a></p>
      <br>
    </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style src="../assets/footer.css"></style>