<template>
  <div id="app" class="enigme-page">
    <div class="enigme-section">
      <div class="enigme-content">
        <h1>
        Des <span class="highlight-purple">énigmes uniques</span>, un défi
        <span class="highlight-purple">constant</span> : jouez en solo,<br />
        <span class="highlight-green">jusqu’où irez-vous ?</span>
        </h1>
        <p>
          Un système de progression par rangs vous permet d'atteindre différents paliers, ouvrant ainsi la possibilité de postuler chez R&MI. Ce système est enrichi par des énigmes intégrées dans une histoire captivante, explorant diverses techniques de cryptographie et proposant des défis de réflexion uniques.
        </p>
      </div>
      <div class="puzzle">
        <img src="/img/puzzle_vert.png" alt="Puzzle Piece Vert" class="puzzle-piece vert" />
        <img src="/img/puzzle_violet.png" alt="Puzzle Piece Violet" class="puzzle-piece violet" />
        <img src="/img/puzzle_blanc.png" alt="Puzzle Piece Blanc" class="puzzle-piece blanc" />
      </div>
    </div>

    <div class="rank-section">
      <div class="rank-content">
        <div class="rank-icons">
          <img src="/img/badges/kameleon.png" alt="Cameleon" class="icon left-icon" />
          <img src="/img/badges/pieuvre.png" alt="Pieuvre" class="icon right-icon" />
          <img src="/img/badges/hibou.png" alt="Hibou" class="icon bottom-icon" />
        </div>
        <div class="rank-text">
          <h1>RANK</h1>
          <p>
            Décode des <span class="highlight-purple">énigmes</span> pour débloquer
            des <span class="highlight-purple">badges</span> uniques et postuler chez
            <span class="highlight-green">R&MI</span> !
          </p>
          <div class="timeline">
  <div class="timeline-container left">
    <div class="score-1">1 Point</div>
    <img src="/img/badges/cochon.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Cochon</h2>
      <p>
        Rang : Cochon
        <br>
        Nombre de point : 1
        <br>
        Nombre d'Utilisateur : 12
      </p>
    </div>
  </div>
  <div class="timeline-container right">
    <div class="score">150 Points</div>
    <img src="/img/badges/hibou.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Hibou</h2>
      <p>
        Rang : Hibou
        <br>
        Nombre de point : 150
        <br>
        Nombre d'Utilisateur : 11
      </p>
    </div>
  </div>
  <div class="timeline-container left">
    <div class="score">300 Points</div>
    <img src="/img/badges/pieuvre.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Pieuvre</h2>
      <p>
        Rang : Pieuvre
        <br>
        Nombre de point : 300
        <br>
        Nombre d'Utilisateur : 320
      </p>
    </div>
  </div>
  <div class="timeline-container right">
    <div class="score">500 Points</div>
    <img src="/img/badges/phasme.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Phasmes</h2>
      <p>
        Rang : Phasmes
        <br>
        Nombre de point : 500
        <br>
        Nombre d'Utilisateur : 9
      </p>
    </div>
  </div>
  <div class="timeline-container left">
    <div class="score">750 Points</div>
    <img src="/img/badges/poisson-pierre.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Poisson Pierre</h2>
      <p>
        Rang : Poisson Pierre
        <br>
        Nombre de point : 750
        <br>
        Nombre d'Utilisateur : 16
      </p>
    </div>
  </div>
  <div class="timeline-container right">
    <div class="score">1000 Points</div>
    <img src="/img/badges/panda-ghillie.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Panda Ghillie</h2>
      <p>
        Rang : Panda Ghillie
        <br>
        Nombre de point : 1000
        <br>
        Nombre d'Utilisateur : 8
      </p>
    </div>
  </div>
  <div class="timeline-container left">
    <div class="score">1500 Points</div>
    <img src="/img/badges/kameleon.png" alt="photo rank" class="icon">
    <div class="timeline-content">
      <h2>Kameleon</h2>
      <p>
        Rang : Kameleon
        <br>
        Nombre de point : 1500
        <br>
        Nombre d'Utilisateur : 3
      </p>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>

    <!-- Add more section here -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="../assets/enigme.css"></style>