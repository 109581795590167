<template>
    <div class="development-container">
        <div class="development-content">
        <h1>Page <span>Versus</span></h1>
        <p>Cette page est actuellement <span class="highlight">en cours de développement</span>.</p>
        <p>Revenez bientôt pour découvrir toutes les fonctionnalités passionnantes !</p>
        <div class="loader"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="../assets/versus.css"></style>
