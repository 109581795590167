<template>
  <div class="test-container">

    <div :class="['maze-container', { 'slide-left': inputVisible }]">
      <section class="controls" >
        <span>Z</span>
        <span>Q</span>
        <span>S</span>
        <span>D</span>
      </section>
      <canvas ref="gameCanvas" width="900" height="540" tabindex="0"></canvas>
    </div>
    <div :class="['password-section', { 'visible': inputVisible }]">
      <p>Vous avez trouvé le coffre ! Entrez le mot de passe.</p>
      <div class="terminal-input">
        <span class="prompt">&gt;</span>
        <div class="input-wrapper">
          <input ref="passwordInput" type="text" v-model="userPassword" placeholder="Votre réponse..." @keyup.enter="validatePassword" />
        </div>
      </div>
      <button @click="validatePassword">Valider</button>
      <div v-if="feedback" :class="['feedback', { 'error': isPasswordIncorrect }]">{{ feedback }}</div>
      <div v-if="tableVisible" class="substitution-table">
        <h3>Table de substitution</h3>
        <div class="table">
          <div v-for="(letter, index) in substitutionTable" :key="index" class="table-item">
            {{ letter }} = {{ index + 1 }}
          </div>
        </div>
      </div>

      <!-- Boutons pour demander les indices -->
      <div class="hint-section">
        <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
        <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
        <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
      </div>

      <!-- Afficher les indices demandés -->
      <div class="hints">
        <p v-if="hints[1]">1: Comptez vos pas</p>
        <p v-if="hints[2]">2: Retirez les directions</p>
        <p v-if="hints[3]">3: Substituez</p>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  name: "EnigmeLabyrinthe",
  data() {
    return {
      maze: [
        [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
        [1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0, 1, 0, 0],
        [0, 0, 1, 0, 1, 0, 0, 0, 1, 1, 1, 0, 1, 0, 0],
        [0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0],
        [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0],
      ],
      key: {
        x: 0,
        y: 0,
      },
      cellSize: 60,
      inputVisible: false,
      tableVisible: false,
      userPassword: "",
      feedback: "",
      isPasswordIncorrect: false,
      keyImage: null,
      substitutionTable: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
      hints: { 1: false, 2: false, 3: false },
    };
  },
  watch: {
    inputVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.passwordInput.focus();
          }, 500);
        });
      }
    }
  },
  mounted() {
    this.loadKeyImage();
    this.loadChestImage();
    this.drawMaze();
    const canvas = this.$refs.gameCanvas;
    canvas.focus();
    canvas.addEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    loadKeyImage() {
      this.keyImage = new Image();
      this.keyImage.src = "/img/key.jpg";
      this.keyImage.onload = () => {
        this.drawMaze();
      };
    },
    loadChestImage() {
      this.chestImage = new Image();
      this.chestImage.src = "/img/coffre.png";
      this.chestImage.onload = () => {
        this.drawMaze();
      };
    },
    drawMaze() {
      const canvas = this.$refs.gameCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let row = 0; row < this.maze.length; row++) {
        for (let col = 0; col < this.maze[row].length; col++) {
          if (this.maze[row][col] === 0) {
            ctx.fillStyle = "#000";
          } else {
            ctx.fillStyle = "#fff";
          }
          ctx.fillRect(col * this.cellSize, row * this.cellSize, this.cellSize, this.cellSize);
          ctx.strokeStyle = "#ddd";
          ctx.strokeRect(col * this.cellSize, row * this.cellSize, this.cellSize, this.cellSize);
        }
      }
      if (this.keyImage) {
        ctx.drawImage(
          this.keyImage,
          this.key.x * this.cellSize + 10,
          this.key.y * this.cellSize + 10,
          this.cellSize - 20,
          this.cellSize - 20
        );
      }
      if (this.chestImage) {
        ctx.drawImage(
          this.chestImage,
          6 * this.cellSize + 10,
          8 * this.cellSize + 10,
          this.cellSize - 20,
          this.cellSize - 20
        );
      }
    },
    handleKeyDown(event) {
      let newX = this.key.x;
      let newY = this.key.y;
      if (event.key === "z") {
        newY = Math.max(0, this.key.y - 1);
      } else if (event.key === "s") {
        newY = Math.min(this.maze.length - 1, this.key.y + 1);
      } else if (event.key === "q") {
        newX = Math.max(0, this.key.x - 1);
      } else if (event.key === "d") {
        newX = Math.min(this.maze[0].length - 1, this.key.x + 1);
      }
      if (this.maze[newY][newX] === 1) {
        this.key.x = newX;
        this.key.y = newY;
        this.drawMaze();
        if (this.key.x === 6 && this.key.y === 8) {
          this.inputVisible = true;
          this.tableVisible = true;
        } else {
          this.feedback = "";
        }
      }
    },
    validatePassword() {
      if (this.userPassword.toLowerCase() === "clef") {
        this.feedback = "Bravo ! Le coffre est ouvert.";
        this.isPasswordIncorrect = false;
      } else {
        this.feedback = "Mot de passe incorrect. Réessayez.";
        this.isPasswordIncorrect = true;
      }
    },
    showHint(hintNumber) {
      // Activer l'indice correspondant
      this.hints[hintNumber] = true;
    },
  },
};
</script>

<style scoped>
.controls {
  position: absolute;
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  place-items: center;
  gap: 0.5rem;
  width: auto; 
  height: auto;
}

.controls span {
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 0.3rem;
  background-color: #fff;
  color: #444;
  border-top: 0.05rem solid #ccc;
  box-shadow: 0 0.2rem 0 0.2rem #ccc;
}


.controls span:nth-child(1) {
  grid-column: 2;
  grid-row: 1;
}

.controls span:nth-child(2) {
  grid-column: 1;
  grid-row: 2;
}

.controls span:nth-child(3) {
  grid-column: 2;
  grid-row: 2;
}

.controls span:nth-child(4) {
  grid-column: 3;
  grid-row: 2;
}

.test-container {
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 105vh;
  position: relative;
  overflow: hidden;
}

.maze-container {
  position: relative; /* Pour que controls soit positionné par rapport à cet élément */
  transition: transform 1.0s ease;
  width: auto;
  height: 60%;
}

.slide-left {
  transform: translateX(-40%);
}

.password-section {
  position: absolute;
  right: -100%;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  gap: 20px;
  transition: right 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  pointer-events: none;
  overflow-y: auto;
}

.password-section p {
  margin-top: 2%;
  font-size: 1.5rem;
  color: #4caf50;
  font-weight: bold;
  text-align: center;
  overflow-wrap: break-word;
}

.password-section.visible {
  right: 0;
  opacity: 1;
  pointer-events: auto;
}

.substitution-table h3 {
  color: #fff;
}

.feedback {
  margin-top: 2%;
  font-size: 1.125rem;
  color: #4caf50;
  font-weight: bold;
  text-align: center;
}

.feedback.error {
  color: red; 
}

.password-section input {
  padding: 2%;
  font-size: 1.5rem;
  width: 70%;
  max-width: 90%;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #000;
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: none;
  caret-color: #fff;
  position: relative;
}

.password-section button {
  font-size: 1rem;
  cursor: pointer;
  padding: 1%;
  width: 30%;
  border-radius: 0.5rem;
  background-color: #ccc;
  color: #fff;
  border: none;
  text-align: center;
}

.table-item {
  padding: 0.5rem;
  background-color: #fff;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333; 
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap;
}

.table-item:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.table {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
}

.substitution-table {
  margin-top: 1rem;
  text-align: center;
}

.substitution-table h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #ccc;
}

.hint-section button {
  padding: 0.5rem 0.5rem;
  margin: 0.2rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  color: #fff;
}

.hint-section button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.hint-section button:not(.disabled) {
  background-color: #f39c12;
}

.hints {
  margin-top: 1rem; 
  font-size: 1.5rem;
  font-style: italic;
  color: #fff;
}

.terminal-input {
  display: flex;
  align-items: center;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.prompt {
  color: #fff;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
</style>