<template>
  <div class="contact">
    <div class="contact-gauche">
      <h4 class="contact-title">Contact</h4>
      <h2>Discutons, contactez-nous</h2>
      <p class="contact-description">
        Vous avez des questions ou des commentaires ? Nous sommes ici pour aider.
        Envoyez-nous un message et nous vous répondrons dans les 24 heures.
      </p>
      <form class="contact-form">
        <p>Nom</p>
        <div class="contact-input-container">
          <input
            type="text"
            class="contact-icon-user"
            placeholder="Entrez votre Nom..."
          />
        </div>
        <p>E-mail</p>
        <div class="contact-input-container">
          <input
            type="email"
            class="contact-icon-mail"
            placeholder="Entrez votre E-mail..."
          />
        </div>
        <p>Message</p>
        <div class="contact-input-container">
          <textarea
            class="contact-icon-message"
            placeholder="Laissez-nous un message..."></textarea>
        </div>
        <div class="contact-terms">
          <label class="contact-container">
            <input type="checkbox" checked="checked" />
            J'accepte les <a href="/mentions-legales">Conditions d'utilisations</a>
          </label>
        </div>
        <button type="submit" class="contact-btn-submit">Envoyez</button>
      </form>
    </div>
    <div class="contact-droite">
      <img src="/img/form.svg" alt="Illustration" class="contact-image" />
      <div class="contact-info">
        <div class="contact-info-item">
          <span class="contact-icon-phone"></span>
          <img src="/img/logo_phone.png" alt="Logo téléphone" class="contact-logo-phone" />
          <p class="contact-droite-espace">Téléphone :</p>
          <p>06 62 62 62 62</p>
        </div>
        <div class="contact-info-item">
          <span class="contact-icon-address"></span>
          <img src="/img/logo_address.png" alt="Logo adresse" class="contact-logo-address" />
          <p class="contact-droite-espace">Adresse :</p>
          <p>Rue de Calais</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLogin",
};
</script>

<style src="../assets/contact.css"></style>
