<template>
  <div class="puzzle-container">
    <!-- Afficher l'image de fond -->
    <div class="image-section">
      <div class="grid-container">
        <img :src="imageSource" alt="Enigme" class="puzzle-image" />
        <canvas ref="gridCanvas" :width="gridWidth" :height="gridHeight" class="puzzle-grid"></canvas>
      </div>
    </div>

    <!-- Consigne -->
    <div class="text-section">
      <p>Tracez jusqu'à deux droites rouges entre les nœuds du quadrillage.</p>
      <p>Équations des droites à utiliser :</p>
      <ul>
        <li>y = 6 - x</li>
        <li>y = 3 + 0,5x</li>
      </ul>
      <button @click="resetCanvas" class="reset-button">Réinitialiser</button>
      <button @click="validateSolution" class="validate-button" :disabled="lines.length < 2">Valider</button>
    </div>

    <!-- Message utilisateur -->
    <div v-if="message" class="message-section">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnigmeCarte",
  data() {
    return {
      imageSource: "/img/carte_enigme_3.png", // Chemin de l'image
      gridWidth: 500, // Largeur du quadrillage
      gridHeight: 500, // Hauteur du quadrillage
      rows: 10, // Nombre de lignes du quadrillage
      cols: 10, // Nombre de colonnes du quadrillage
      nodeSize: 8, // Taille des nœuds (en pixels)
      startNode: null, // Point de départ sélectionné
      lines: [], // Liste des droites tracées
      intersections: [], // Liste des intersections calculées
      gridContext: null, // Contexte 2D du canvas
      message: "", // Message utilisateur
      drawingDisabled: false, // Empêcher le traçage de nouvelles droites
      expectedIntersection: { x: 166, y: 222 }, // Intersection attendue
    };
  },
  mounted() {
    this.drawGrid();
    const canvas = this.$refs.gridCanvas;
    canvas.addEventListener("mousedown", this.handleMouseDown);
  },
  methods: {
    drawGrid() {
      const canvas = this.$refs.gridCanvas;
      this.gridContext = canvas.getContext("2d");

      const ctx = this.gridContext;
      ctx.clearRect(0, 0, this.gridWidth, this.gridHeight);

      const spacingX = this.gridWidth / (this.cols - 1);
      const spacingY = this.gridHeight / (this.rows - 1);

      // Dessiner les droites déjà tracées
      this.lines.forEach((line) => {
        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(line.start.x, line.start.y);
        ctx.lineTo(line.end.x, line.end.y);
        ctx.stroke();
      });

      // Ajouter les axes gradués
      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          const x = col * spacingX;
          const y = row * spacingY;

          // Dessiner les nœuds sauf sur la première et dernière ligne ou colonne
          if (row > 0 && row < this.rows - 1 && col !== 0 && col !== this.cols - 1) {
            ctx.fillStyle =
              this.startNode &&
              this.startNode.x === x &&
              this.startNode.y === y
                ? "blue"
                : "black";

            ctx.beginPath();
            ctx.arc(x, y, this.nodeSize / 2, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      }
    },
    handleMouseDown(event) {
      if (this.drawingDisabled) return; // Empêcher de dessiner si désactivé

      const canvas = this.$refs.gridCanvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const node = this.getClosestNode(x, y);

      if (node) {
        if (!this.startNode) {
          // Si aucun point de départ, définir le nœud cliqué comme départ
          this.startNode = node;
        } else if (this.lines.length < 2) {
          // Limiter le nombre de droites à 2
          const start = this.startNode;
          const end = node;

          // Calculer les points d'intersection avec les bords du canvas
          const extendedLine = this.getExtendedLine(start, end);
          this.lines.push(extendedLine);

          // Calculer les intersections avec toutes les lignes existantes
          this.calculateIntersections(extendedLine);

          // Réinitialiser le point de départ
          this.startNode = null;

          if (this.lines.length === 2) {
            this.message = "Vous avez tracé deux droites. Validez pour vérifier votre solution.";
            this.drawingDisabled = true;
          }
        }
        this.drawGrid(); // Redessiner pour mettre à jour les droites, intersections et surbrillances
      }
    },
    calculateIntersections(newLine) {
      this.lines.forEach((line) => {
        const intersection = this.getLineIntersection(
          newLine.start,
          newLine.end,
          line.start,
          line.end
        );
        if (intersection) {
          this.intersections.push(intersection);
        }
      });
    },
    validateSolution() {
      if (this.intersections.some((point) => this.isCloseToExpected(point))) {
        this.message = "Bravo ! Vous avez trouvé la solution.";
      } else {
        this.message = "Erreur ! Essayez de nouveau.";
      }
    },
    isCloseToExpected(point) {
      const tolerance = 1; // Tolérance pour la précision
      return (
        Math.abs(point.x - this.expectedIntersection.x) <= tolerance &&
        Math.abs(point.y - this.expectedIntersection.y) <= tolerance
      );
    },
    resetCanvas() {
      // Réinitialiser toutes les données
      this.lines = [];
      this.intersections = [];
      this.startNode = null;
      this.message = "";
      this.drawingDisabled = false;
      this.drawGrid(); // Redessiner un canvas vierge
    },
    getLineIntersection(p1, p2, p3, p4) {
      const denominator =
        (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);

      if (denominator === 0) return null; // Pas d'intersection (droites parallèles)

      const t =
        ((p1.x - p3.x) * (p3.y - p4.y) - (p1.y - p3.y) * (p3.x - p4.x)) /
        denominator;
      const u =
        ((p1.x - p3.x) * (p1.y - p2.y) - (p1.y - p3.y) * (p1.x - p2.x)) /
        denominator;

      if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        // Intersection dans les segments
        return {
          x: p1.x + t * (p2.x - p1.x),
          y: p1.y + t * (p2.y - p1.y),
        };
      }

      return null; // Pas d'intersection dans les segments
    },
    getClosestNode(x, y) {
      const spacingX = this.gridWidth / (this.cols - 1);
      const spacingY = this.gridHeight / (this.rows - 1);

      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          if (row === 0 || row === this.rows - 1 || col === 0 || col === this.cols - 1)
            continue;

          const nodeX = col * spacingX;
          const nodeY = row * spacingY;
          const distance = Math.sqrt((x - nodeX) ** 2 + (y - nodeY) ** 2);

          if (distance <= this.nodeSize) {
            return { x: nodeX, y: nodeY };
          }
        }
      }
      return null;
    },
    getExtendedLine(start, end) {
      const dx = end.x - start.x;
      const dy = end.y - start.y;

      const slope = dy / dx;
      const intercept = start.y - slope * start.x;

      const points = [];

      if (dx !== 0) {
        points.push({ x: 0, y: intercept });
        points.push({ x: this.gridWidth, y: slope * this.gridWidth + intercept });
      }

      if (dy !== 0) {
        points.push({ x: -intercept / slope, y: 0 });
        points.push({ x: (this.gridHeight - intercept) / slope, y: this.gridHeight });
      }

      const validPoints = points.filter(
        (point) =>
          point.x >= 0 &&
          point.x <= this.gridWidth &&
          point.y >= 0 &&
          point.y <= this.gridHeight
      );

      if (validPoints.length >= 2) {
        return { start: validPoints[0], end: validPoints[1] };
      }

      return null; // Pas de points valides
    },
  },
};
</script>

<style>
.puzzle-container {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 20px auto;
  max-width: 600px;
}
.grid-container {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
.puzzle-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.puzzle-grid {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: auto;
}
.text-section {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
}
.reset-button,
.validate-button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.validate-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.message-section {
  margin-top: 20px;
  font-size: 16px;
  color: red;
  font-weight: bold;
}
</style>
