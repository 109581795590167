<template>
    <div class="skill-tree-container">
      <h1>Arbre de Compétences</h1>
      <div class="canvas-container">
        <canvas 
          ref="canvas" 
          :width="canvasWidth" 
          :height="canvasHeight"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"
          @mousemove="onMouseMove"
          @wheel.prevent="onMouseWheel"
          @click="onCanvasClick"
        ></canvas>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SkillTree',
    data() {
      return {
        tree: {
          id: 'root',
          name: 'Énigme 1',
          route: '/enigmelabyrinthe',
          children: [
            {
              id: 'enigme2',
              name: 'Énigme 2',
              route: 'enigmecarte',
              children: [
                { id: 'enigme3', name: 'Énigme 3', route:'/enigmehistoire', children: [] },
                { id: 'enigme4', name: 'Énigme 4', route:'/', children: [] }
              ]
            },
            {
              id: 'enigme5',
              name: 'Énigme 5',
              route:'/',
              children: [
                { id: 'enigme6', name: 'Énigme 6', route:'/', children: [] }
              ]
            }
          ]
        },
        nodes: [],
        edges: [],
        canvasWidth: 1000,
        canvasHeight: 800,
  
        offsetX: 0,
        offsetY: 0,
        scale: 1,
  
        isDragging: false,
        dragStartX: 0,
        dragStartY: 0,
        dragOffsetX: 0,
        dragOffsetY: 0,
  
        nodeWidth: 120,
        nodeHeight: 50
      }
    },
    mounted() {
      this.layoutTree(this.tree);
      this.drawTree();
    },
    methods: {
      layoutTree(root) {
        const levelHeight = 150;
        const nodeWidth = this.nodeWidth;
        // const nodeHeight = this.nodeHeight;
        let maxDepth = 0;
  
        const getDepth = (node, depth=0) => {
          if (depth > maxDepth) maxDepth = depth;
          node.depth = depth;
          node.children.forEach(child => getDepth(child, depth+1));
        };
  
        getDepth(root);
  
        let levels = [];
        for (let i = 0; i <= maxDepth; i++) {
          levels[i] = [];
        }
  
        const assignNodePositions = (node) => {
          levels[node.depth].push(node);
          node.children.forEach(child => assignNodePositions(child));
        };
  
        assignNodePositions(root);
  
        levels.forEach((level, depth) => {
          let total = level.length;
          let startX = -(total * nodeWidth)/2; 
          level.forEach((node, i) => {
            node.x = startX + i * (nodeWidth + 50);
            node.y = depth * levelHeight;
          });
        });
  
        this.nodes = [];
        const flatten = (node) => {
          this.nodes.push(node);
          node.children.forEach(c => flatten(c));
        };
        flatten(root);
  
        this.edges = [];
        const addEdges = (node) => {
          node.children.forEach(child => {
            this.edges.push({ from: node, to: child });
            addEdges(child);
          });
        };
        addEdges(root);
      },
  
      drawTree() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  
        ctx.save();
        ctx.translate(this.offsetX, this.offsetY);
        ctx.scale(this.scale, this.scale);
  
        this.edges.forEach(edge => {
          this.drawEdge(ctx, edge.from, edge.to);
        });
  
        this.nodes.forEach(node => {
          this.drawNode(ctx, node);
        });
  
        ctx.restore();
      },
  
      drawNode(ctx, node) {
        const w = this.nodeWidth;
        const h = this.nodeHeight;
        const x = node.x - w/2;
        const y = node.y - h/2;
  
        ctx.fillStyle = '#444';
        ctx.strokeStyle = '#FFF';
        ctx.lineWidth = 2;
  
        ctx.fillRect(x, y, w, h);
        ctx.strokeRect(x, y, w, h);
  
        ctx.fillStyle = '#fff';
        ctx.font = '16px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(node.name, x + w/2, y + h/2);
  
        // node hitbox
        node.hitBox = { x, y, w, h };
      },
  
      drawEdge(ctx, from, to) {
        ctx.strokeStyle = '#aaa';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(from.x, from.y + 25); 
        ctx.lineTo(to.x, to.y - 25);
        ctx.stroke();
      },
  
      // Nav on canvas
      onMouseDown(e) {
        this.isDragging = true;
        this.dragStartX = e.clientX;
        this.dragStartY = e.clientY;
        this.dragOffsetX = this.offsetX;
        this.dragOffsetY = this.offsetY;
      },
      onMouseUp() {
        this.isDragging = false;
      },
      onMouseMove(e) {
        if (this.isDragging) {
          const dx = e.clientX - this.dragStartX;
          const dy = e.clientY - this.dragStartY;
          this.offsetX = this.dragOffsetX + dx;
          this.offsetY = this.dragOffsetY + dy;
          this.drawTree();
        }
      },
      onMouseWheel(e) {
        // Zoom
        const zoomFactor = 0.1;
        if (e.deltaY < 0) {
          this.scale *= (1 + zoomFactor);
        } else {
          this.scale *= (1 - zoomFactor);
        }
        this.drawTree();
      },
  
      // Click detection
      onCanvasClick(e) {
        // Get coordinate
        const rect = this.$refs.canvas.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const clickY = e.clientY - rect.top;
  
        // Convert
        const worldX = (clickX - this.offsetX) / this.scale;
        const worldY = (clickY - this.offsetY) / this.scale;
        
        // Parcourir tous les noeuds
        for (let node of this.nodes) {
            if (!node.hitBox) continue;
            const { x, y, w, h } = node.hitBox;
            if (worldX >= x && worldX <= x + w && worldY >= y && worldY <= y + h) {
            // Le clic est dans la zone de ce node
            if (node.route) {
                this.$router.push({ path: node.route });
            }
            break; // On quitte la boucle une fois qu'on a trouvé le noeud cliqué
            }
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .skill-tree-container {
    color: #fff;
    font-family: "Arial", sans-serif;
    background: #1a1a2e;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  
  .canvas-container {
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
  }
  
  canvas {
    background: #1a1a2e;
    border: 1px solid #a32cc4;
    display: block;
    margin: 0 auto;
    cursor: grab;
  }
  
  canvas:active {
    cursor: grabbing;
  }
  
  h1 {
    text-align: center;
    margin: 0;
    padding: 20px;
    font-size: 2rem;
    color: #a32cc4;
  }
  </style>
  