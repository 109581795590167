<template>
  <div class="container-EnigmeHistoire">
    <!-- Afficher l'image de l'énigme -->
    <div class="left-part">
      <img :src="imageSource" alt="Enigme" class="puzzle-img-EnigmeHistoire" />
      <!-- Ajouter la légende sous l'image -->
      <div class="image-caption-EnigmeHistoire">
        <p><strong>Vercingétorix jette ses armes aux pieds de César</strong></p>
        <p>Peint par Lionel Royer, 1899</p>
        <p>Musée Crozatier, Le Puy-en-Velay, France</p>
      </div>
    </div>

    <div class="right-part">
      <!-- Table de substitution toujours visible -->
      <div class="substitution-table">
        <h3>Table de substitution</h3>
        <div class="table">
          <div v-for="(letter, index) in substitutionTable" :key="index" class="table-item">
            {{ letter }} = {{ index + 1 }}
          </div>
        </div>
      </div>

      <!-- Afficher le texte crypté -->
      <div class="text-section-EnigmeHistoire">
        <p>{{ cryptedText }}</p>
      </div>

      <!-- Saisie de la réponse -->
      <div class="input-section-EnigmeHistoire">
        <div class="terminal-input">
          <span class="prompt">&gt;</span>
          <div class="input-wrapper">
            <input
              type="text"
              v-model="userInput"
              placeholder="Votre réponse..."
              @keyup.enter="validateAnswer"
            />
          </div>
        </div>
        <button @click="validateAnswer">Valider</button>
      </div>

      <!-- Boutons pour demander les indices -->
      <div class="hint-section-EnigmeHistoire">
        <button 
          :disabled="false" 
          @click="showHint(1)" 
          :class="{ disabled: false }">
          Indice 1
        </button>
        <button 
          :disabled="!hints[1]" 
          @click="showHint(2)" 
          :class="{ disabled: !hints[1] }">
          Indice 2
        </button>
        <button 
          :disabled="!hints[2]" 
          @click="showHint(3)" 
          :class="{ disabled: !hints[2] }">
          Indice 3
        </button>
      </div>

      <!-- Afficher les indices demandés -->
      <div class="hints-EnigmeHistoire">
        <p v-if="hints[1]">1: Le décalage est dissimulé dans la légende du tableau</p>
        <p v-if="hints[2]">2: Un calcul est nécessaire selon le nombre de caractères dans la table de substitution</p>
        <p v-if="hints[3]">3: 1899%30</p>
      </div>

      <!-- Afficher le feedback -->
      <div v-if="feedback" class="feedback-EnigmeHistoire">
        <p>{{ feedback }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnigmeHistoire",
  data() {
    return {
      imageSource: "/img/vercingetorix_jette_ses_armes_aux_pieds_de_cesar.png", // Chemin de l'image
      cryptedText: ".JUJMN LN.J,", // Texte crypté
      userInput: "", // Réponse de l'utilisateur
      feedback: "", // Feedback après validation
      substitutionTable: "ABCDEFGHIJKLMNOPQRSTUVWXYZ,.?!".split(""), // Table de substitution
      correctAnswer: "SALADE CESAR", // Réponse correcte
      hints: { 1: false, 2: false, 3: false }, // Suivi des indices affichés
    };
  },
  methods: {
    validateAnswer() {
      // Vérifier si la réponse est correcte (ignorer la casse)
      if (this.userInput.toUpperCase() === this.correctAnswer) {
        this.feedback = "Bravo ! Vous avez trouvé la réponse.";
      } else {
        this.feedback = "Erreur ! Réessayez.";
      }
    },
    showHint(hintNumber) {
      // Activer l'indice correspondant
      this.hints[hintNumber] = true;
    },
  },
};
</script>


<style scoped>
.container-EnigmeHistoire {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.left-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.left-part img {
  max-width: 80%;
  height: auto;
  border: 2px solid #000;
  margin-bottom: 10px;
}

.image-caption-EnigmeHistoire {
  background-color: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  font-style: italic;
  margin-top: 10px;
}

.right-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  overflow-y: auto;
}

.substitution-table {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #ccc;
}

.substitution-table h3{
  text-align: center;
}

.table {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
}

.table-item {
  padding: 0.5rem;
  background-color: #fff;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333; 
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap;
}

.table-item:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.text-section p {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.text-section-EnigmeHistoire p {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.input-section-EnigmeHistoire {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terminal-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.prompt {
  color: #fff;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.input-section-EnigmeHistoire input {
  padding: 2%;
  font-size: 1.5rem;
  width: 70%;
  max-width: 90%;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #000;
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: none;
  caret-color: #fff;
  position: relative;
}

.input-section-EnigmeHistoire button {
  font-size: 1rem;
  cursor: pointer;
  padding: 1%;
  width: 30%;
  border-radius: 0.5rem;
  background-color: #ccc;
  color: #fff;
  border: none;
  text-align: center;
}

.hint-section-EnigmeHistoire button {
  padding: 10px;
  margin: 5px;
  background-color: #f39c12;
  color: white;
  border: none;
  border-radius: 5px;
}

.hints-EnigmeHistoire {
  margin-top: 20px;
  font-size: 16px;
  font-style: italic;
}

.feedback-EnigmeHistoire {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
}
</style>