import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import EnigmePage from './components/EnigmePage.vue';
import AppLogin from './components/AppLogin.vue';
import AppSignIn from './components/AppSignIn.vue';
import AppContact from './components/AppContact.vue';
import ClanPage from './components/ClanPage.vue';
import FaqPage from './components/FaqPage.vue';
import EnigmeLabyrinthe from './components/EnigmeLabyrinthe.vue';
import EnigmeCarte from './components/EnigmeCarte.vue';
import EnigmeHistoire from './components/EnigmeHistoire.vue';
import VersusPage from './components/VersusPage.vue';
import SkillTree from './components/SkillTree.vue';
import ClanPage_login from './components/ClanPage_login.vue';

const routes = [
  { path: '/', component: HomePage, meta: { title: 'Kameleon' } },
  { path: '/enigme', component: EnigmePage, meta: { title: 'Enigme' } },
  { path: '/login', component: AppLogin, meta: { title: 'Connexion' } },
  { path: '/sign-in', component: AppSignIn, meta: { title: 'Inscription' } },
  { path: '/contact', component: AppContact, meta: { title: 'Contact' } },
  { path: '/clan', component: ClanPage, meta: { title: 'Clan' } },
  { path: '/clan_login', component: ClanPage_login, meta: { title: 'Clan_login' } },
  { path: '/faq', component: FaqPage, meta: { title: 'FAQ' } },
  { path: '/enigmelabyrinthe', component: EnigmeLabyrinthe, meta: { title: 'Enigme Labyrinthe' } },
  { path: '/enigmecarte', component: EnigmeCarte, meta: { title: 'Enigme Carte' } },
  { path: '/enigmehistoire', component: EnigmeHistoire, meta: { title: 'Enigme Histoire' } },
  { path: '/versus', component: VersusPage, meta: { title: 'Versus' } },
  { path: '/skilltree/', component: SkillTree, meta: { title: 'Enigme Tree' } }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;